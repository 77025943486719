import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgEarlyOperationCover from "../../assets/images/journey/early-operation/early-operation-cover.png"

const EarlyOperationPage = () => (
  <Layout>
    <SEO title="Early Operation" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40" style={{borderBottom: "5px solid #D9A032"}}>     
        <img src={imgEarlyOperationCover} alt="" data-uk-cover />
            <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div>
                  <h2 className="relative">
                    EARLY OPERATION
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  Once the Hope Probe separates from the upper stage, an automated sequence will begin to awaken the probe. The central computer will boot up and turn on the heaters to prevent the fuel from freezing. It will then deploy the solar array panels and use the sun sensors to find the sun and manoeuvre in such a way that the solar arrays begin to charge the onboard battery.
                  </p>
                  <p>
                  With the power switched on, the Hope Probe will start transmitting to Earth, with the NASA Deep Space Network ground station in Madrid ready to pick up on the signals. Once the signal is received on the ground station, the EMM Operations Team will begin their checks on the spacecraft.
                  </p>
                  <p>
                  After the communications system is checked out, the control system will ensure that the spacecraft is pointed in the right direction. The propulsion system onboard will ensure detailed manoeuvres to refine the Hope Probe’s trajectory towards Mars.
                  </p>
                  <p>
                  The Hope Probe is now ready for its 7-month long journey to Mars covering 493,500,000 km.
                  </p>
                </div>
            </div>  
                 
          </div>  
      </section>
    </main>
  </Layout>
)

export default EarlyOperationPage
